import React, { useEffect, useState } from "react";
import './Portfolio.scss';
export default function Portfolio() {
    return (
        <div id="portfolio">
            <div>
                <p className="section-subtitle">
                    What I Did ?
                </p>
                <h2 className="section-title">
                    Portfolio
                </h2>
            </div>
            <div className="container">
                <div className="item">
                    <img src="/folio-1.jpg" alt="folio-1" />
                </div>
                <div className="item">
                    <img src="/folio-1.jpg" alt="folio-2" />
                </div>
                <div className="item">
                    <img src="/folio-1.jpg" alt="folio-3" />
                </div>
            </div>
        </div>
    );
}